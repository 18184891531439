import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Arrow from "../assets/svg/arrow.inline.svg";
import BigArrow from "../assets/svg/arrowbigyellow.inline.svg";
import styled from "styled-components";
import SuperText from "../components/SuperText";
import FrontPageLinks from "../components/FrontPageLinks";
import { randomColor } from "../utils/random-color";
import { Grid, Flex, Box } from "@chakra-ui/react";
import SEO from "../components/seo";
import Nav from "../components/Nav";
import { translate } from "../utils/get-locale";

const Description = styled.div`
	p {
		font-family: "Poppins";
		font-weight: 300;
		font-style: normal;
		font-size: 0.875rem;
		line-height: 1.4;
		text-align: center;
		color: #262626;
		margin: 1.875rem;
	}
`;

const BoldText = styled.p`
	font-family: "Poppins";
	font-weight: 700;
	font-style: normal;
	font-size: 1.3rem;
	line-height: 1;
	text-align: center;
	color: #262626;
	padding-left: 20px;
	padding-bottom: 14px;
`;

export default function IndexTemplate({
	edges,
	page,
	needles,
	locale,
	mecInfoPage,
	contactPage,
	clickPage
}) {
	const arrow = createRef();

	useEffect(() => {}, []);

	const mouseOver = () => {
		gsap.fromTo(arrow.current, { x: 0 }, { duration: 0.15, x: 10 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, x: 0 });
	};

	return (
		<>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Nav locale={locale} infoPage={mecInfoPage} contactPage={contactPage} clickPage={clickPage} />
			<div className="index-root">
				<Grid
					templateAreas={[
						`"center" "right" "left"`,
						`"center" "right" "left"`,
						`"center" "right" "left"`,
						`"left center right"`,
					]}
					//gridColumns={["1fr", "1fr 1fr 1fr"]}
					px={"16px"}
					mb={["5rem", 0]}
					justifyContent="center"
				>
					<Flex
						direction="column"
						alignItems="center"
						w={["100%", "100%", "100%", "310px"]}
						maxW="460px"
						gridArea="left"
						mt={["30px", "30px", "30px", 0]}
					>
						<FrontPageLinks
							pages={edges}
							needle={needles.muovailu}
							locale={locale}
						/>
						<Box h={30} />
						<FrontPageLinks
							pages={edges}
							needle={needles.yhteistyo}
							locale={locale}
						/>
						<Box h={["40px", "40px", "40px", "80px"]} />
						<FrontPageLinks
							pages={edges}
							needle={needles.rohkaisu}
							locale={locale}
						/>
					</Flex>
					<Box
						width="100%"
						maxWidth={510}
						mt={["25px", "25px", "25px", 0]}
						px={[0, "30px"]}
						gridArea="center"
					>
						<SuperText text="More" color="#fcafc0" />
						<SuperText text="(than)" color="#523178" />
						<SuperText text="Eight" color="#ff8f1c" />
						<SuperText text="Colours" color="#888d30" />

						<Box height="40px" />
						<Link
							paintDrip
							hex={randomColor()}
							className="link"
							to={
								locale
									? `/${locale}/${mecInfoPage.slug}`
									: `/${mecInfoPage.slug}`
							}
							//onMouseEnter={mouseOver}
							//onMouseLeave={mouseLeave}
						>
							<div className="svg-wrapper">
								<BigArrow />
							</div>
							<BoldText>{mecInfoPage.title}</BoldText>
						</Link>

						<Description
							dangerouslySetInnerHTML={{ __html: page.content }}
						/>

						<AniLink
							paintDrip
							hex={randomColor()}
							className="link"
							to={locale
								? `/${locale}/products`
								: `/products`}
							onMouseEnter={mouseOver}
							onMouseLeave={mouseLeave}
						>
							<div className="svg-wrapper" ref={arrow}>
								<Arrow />
							</div>
							<span>{translate("mectuotteet")}</span>
						</AniLink>
					</Box>
					<Flex
						direction="column"
						alignItems="center"
						w={["100%", "100%", "100%", "310px"]}
						maxW="460px"
						gridArea="right"
						mt={["50px", "50px", "50px", 0]}
					>
						<FrontPageLinks
							pages={edges}
							needle={needles.hiljainen}
							locale={locale}
						/>
						<Box h={50} />
						<FrontPageLinks
							pages={edges}
							needle={needles.historia}
							locale={locale}
						/>
						<Box h={"20px"} />
						{
							//<FrontPageLinks pages={edges} needle={needles.touko} locale={locale} />
						}
					</Flex>
				</Grid>
			</div>
		</>
	);
}
